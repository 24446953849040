@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

.home-container {
  min-height: 100vh;
  background: #ffffff;
  color: #000409;
  font-family: 'Lexend', -apple-system, BlinkMacSystemFont, sans-serif;
  overflow-x: hidden;
  padding: 0rem;
} 

/* Header */
.header {
  width: 100%;
  background: #ffffff;
  padding: 0rem 2rem;
  position: relative;
}

.header > div {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 1rem;
}

.logo {
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0rem;
  color: #53A4E1;
  
}

h1 {
  letter-spacing: -0.05em;
  margin-bottom: 0.1rem;
  font-weight: 900;
}

.powered-by-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.telegram-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.powered-by {
  font-size: 0.8rem;
  color: #53A4E1;
}

/* Main content wrapper */
.content-wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 2rem;
}

/* Hero Section */
.hero-section {
  text-align: center;
  padding: 4rem 1rem;
  margin: 0 auto;
  color: #05377C;
}

.mobile-hero-image {
  display: none;
  width: 100%;
  max-width: 400px;
  aspect-ratio: 1/1;
  object-fit: cover;
  margin: 0 auto 2rem auto;
  border-radius: 12px;
}

/* Creators Section */
.creators-section {
  padding: 4rem 0;
  max-width: 1440px;
  margin: 0 auto;
}

.section-title {
  padding: 0 2rem;
  font-size: 2rem;
  font-weight: 900;
  letter-spacing: -0.02em;
  
}

.creators-grid {
  padding: 0 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 1.5rem;
}

/* Features Section */
.features-section {
  padding: 4rem 0;
  max-width: 1440px;
  margin: 0 auto;
}

.feature {
  margin-bottom: 6rem;
  position: relative;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  gap: 4rem;
}

.feature-content {
  flex: 1;
}

.feature-image-wrapper {
  flex: 1;
}

/* Reverse layout for the middle feature */
.feature.reverse {
  flex-direction: row-reverse;
}

/* Tag Styles */

/* Style 1: Revenue Stream Tag - Used in first feature */
.tag.revenue {
  color: #29A6E7;
  border-radius: 100px;
  letter-spacing: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0rem;
}

/* Style 2: VIP Tag - Used in second feature */
.tag.vip {
  color: #29A6E7;
  border-radius: 100px;
  letter-spacing: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0rem;
}

/* Style 3: Analytics Tag - Used in third feature */
.tag.analytics {
  color: #29A6E7;
  border-radius: 100px;
  letter-spacing: 0.5rem;
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 0rem;
}

/* Remove the old tag style */
.tag {
  display: inline-block;
  margin-bottom: 1rem;
}

.feature h2 {
  margin-top: 0.1rem;
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 1rem;
  color: #1E1E1E;
  letter-spacing: -0.02em;
}

.feature p {
  font-size: 1.125rem;
  color: #666;
  max-width: 500px;
  line-height: 1.6;
}

.feature-image {
  width: 100%;
  height: 500px;
  border-radius: 12px;
}

.phone-mockup {
  background-image: url('../assets/mockups/phone.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.chat-mockup {
  background-image: url('../assets/mockups/chat.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.stats-mockup {
  background-image: url('../assets/mockups/stats.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.marquee {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  background: #F3F4F6;
  color: #05377C;
  padding: 1rem 0;
}

.marquee-content {
  display: inline-flex;
  white-space: nowrap;
  animation: marquee 600s linear infinite;
  will-change: transform;
  transform: translate3d(-50%, 0, 0);
}

.marquee-content span {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.25rem;
  letter-spacing: -0.02em;
}

.join {
  font-weight: 500;
}

.domain {
  font-weight: 800;
}

.now {
  font-weight: 500;
  margin-right: 2rem;
}

@keyframes marquee {
  0% {
    transform: translate3d(-50%, 0, 0);
  }
  100% {
    transform: translate3d(-150%, 0, 0);
  }
}

/* Add hover pause effect */
.marquee:hover .marquee-content {
  animation-play-state: paused;
}

.cta-box {
  position: relative;
  background: linear-gradient(135deg, #0088CC, #004e75);
  color: white;
  padding: 4rem 0;
  text-align: center;
  overflow: hidden;
}

.cta-box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('../assets/images/grain.png');
  background-repeat: repeat;
  background-size: cover;
  opacity: 0.4;
  pointer-events: none;
  z-index: 2;
}

.cta-box > * {
  position: relative;
  z-index: 2;
}

.cta-box h2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 4rem;
  line-height: 1.1;
  margin-bottom: 1.5rem;
  letter-spacing: -0.02em;
}

.cta-static {
  font-size: 1.0rem;
  font-weight: 800;
  color: rgb(255, 255, 255);
  margin-bottom: 0.25rem;
  letter-spacing: -0.02em;
  text-transform: uppercase;
}

/* Web styles */
@media (min-width: 769px) {
  .cta-slider-container {
    height: 4.4rem;
    overflow: hidden;
    position: relative;
  }

  .cta-slider {
    animation: slideUpWeb 12s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    position: relative;
  }

  .cta-slide {
    height: 4.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-size: 3.5rem;
    color: white;
  }

  @keyframes slideUpWeb {
    0%, 15% {
      transform: translateY(0);
    }
    20%, 35% {
      transform: translateY(-4.4rem);
    }
    40%, 55% {
      transform: translateY(-8.8rem);
    }
    60%, 75% {
      transform: translateY(-13.2rem);
    }
    80%, 95% {
      transform: translateY(-17.6rem);
    }
    100% {
      transform: translateY(-22rem);
    }
  }
}

/* Mobile styles */
@media (max-width: 768px) {
  .cta-box {
    padding: 3rem 0;
  }

  .cta-box h2 {
    font-size: 2.5rem;
  }

  .cta-static {
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  .cta-slider-container {
    height: 2.4rem;
    overflow: hidden;
    position: relative;
  }

  .cta-slider {
    animation: slideUpMobile 12s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    position: relative;
  }

  .cta-slide {
    height: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    font-size: 2rem;
    color: white;
  }

  @keyframes slideUpMobile {
    0%, 15% {
      transform: translateY(0);
    }
    20%, 35% {
      transform: translateY(-2.4rem);
    }
    40%, 55% {
      transform: translateY(-4.8rem);
    }
    60%, 75% {
      transform: translateY(-7.2rem);
    }
    80%, 95% {
      transform: translateY(-9.6rem);
    }
    100% {
      transform: translateY(-12rem);
    }
  }
}

/* Small mobile adjustments */
@media (max-width: 480px) {
  .cta-box {
    padding: 2rem 0;
  }

  .cta-static {
    font-size: 0.75rem;
  }

  .cta-box h2 {
    font-size: 2rem;
  }

  .mobile-hero-image {
    display: block;
  }
  
  .header {
    padding: 0rem;
  }
  .content-wrapper {
    padding: 0rem;
  } 
  .hero-section {
    text-align: left;
  }
  .hero-title {
    text-align: left;
    font-size: 2.4rem;
    font-weight: 800;
  }
  .hero-subtitle {
    text-align: left;
  }
  
  .creators-grid {
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
  
  .feature h2 {
    font-size: 1.75rem;
  }
  
  .feature-image {
    height: 250px;
  }
}

.footer {
  background: #ffffff;
  color: #1E1E1E;
  padding: 2rem 0;
  width: 100%;
}

.footer-content {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer-title {
  font-weight: 800;
  font-size: 1rem;
  color: #1E1E1E;
  margin-bottom: 0.5rem;
}

.footer-social {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.footer-instagram-icon {
  width: 16px;
  height: 16px;
  object-fit: contain;
}

.footer-handle {
  color: #53A4E1;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.2s ease;
}

.footer-handle:hover {
  color: #2980b9;
}

.footer-center {
  text-align: center;
}

.footer-logo {
  color: #53A4E1;
  font-size: 1.5rem;
  font-weight: 700;
  letter-spacing: -0.02em;
}

.footer-right {
  text-align: right;
}

.footer-copyright {
  color: #9CA3AF;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  
}

.footer-year {
  margin-top: 0.25rem;
}

.home-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  max-width: 1440px;
  margin: 0 auto;
}

.nav-logo {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1.25rem;
  font-weight: 600;
  color: #006FC9;
}

.nav-icon {
  width: 28px;
  height: 28px;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23006FC9"><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm4.64 6.8c-.15 1.58-.8 5.42-1.13 7.19-.14.75-.42 1-.68 1.03-.58.05-1.02-.38-1.58-.75-.88-.58-1.38-.94-2.23-1.5-.99-.65-.35-1.01.22-1.59.15-.15 2.71-2.48 2.76-2.69.01-.03.01-.14-.07-.2-.08-.06-.19-.04-.27-.02-.11.02-1.93 1.23-5.46 3.62-.52.36-.99.53-1.41.52-.46-.01-1.35-.26-2.01-.47-.81-.26-1.45-.4-1.4-.85.03-.22.46-.45 1.3-.68 5.09-2.22 8.49-3.68 10.19-4.41.77-.33 1.48-.49 1.99-.49.11 0 .26.01.37.04.41.08.67.25.76.54.06.19.06.4.01.65z"/></svg>');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.nav-button {
  padding: 0.75rem 1.5rem;
  background: #006FC9;
  color: white;
  text-decoration: none;
  border-radius: 8px;
  font-weight: 500;
  color: #006FC9;
  transition: background-color 0.2s ease;
}

.nav-button:hover {
  background: #005ba3;
}

.home-main {
  padding: 8rem 2rem 4rem;
  max-width: 1200px;
  margin: 0 auto;
}

.hero-title {
  font-size: 5.5rem;
  letter-spacing: -0.05em;
  line-height: 1;
  margin-bottom: 1.5rem;
  color: #020b18;
  white-space: nowrap;
}

.hero-title .join,
.hero-title .now {
  font-weight: 500;
}

.hero-title .domain {
  font-weight: 700;
}

.hero-subtitle {
  font-size: 1.25rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.become-creator-btn {
  display: inline-block;
  padding: 1rem 2rem;
  background: linear-gradient(90deg, #29A6E7 0%, #00629E 100%);
  box-shadow: 30px 28px 16px 0px rgba(0, 0, 0, 0.01), 17px 16px 14px 0px rgba(0, 0, 0, 0.05), 8px 7px 10px 0px rgba(0, 0, 0, 0.09), 2px 2px 6px 0px rgba(0, 0, 0, 0.10);
  color: white;
  text-decoration: none;
  border-radius: 100px;
  font-size: 1.1rem;
  letter-spacing: -0.04em;
  font-weight: 600;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.become-creator-btn:hover {
  background: linear-gradient(90deg, #29A6E7 0%, #003352 100%);

}

.become-creator-footer {
  display: inline-block;
  padding: 1rem 2rem;
  color: white;
  text-decoration: none;
  border-radius: 100px;
  font-size: 1.5rem;
  font-weight: 900;
  letter-spacing: -0.04em;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.example-text {
  color: #666;
  font-size: 0.875rem;
}

@media (max-width: 1024px) {
  .feature, 
  .feature.reverse {
    flex-direction: column;
    gap: 2rem;
    text-align: left;
  }

  .feature-content {
    width: 100%;
  }

  .feature-image-wrapper {
    width: 100%;
  }

  .feature p {
    max-width: 100%;
  }

  .feature-image {
    height: 400px;
  }
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 2.4rem;
  }
  
  .feature {
    padding: 0 1rem;
  }
  
  .feature h2 {
    font-size: 2rem;
  }
  
  .cta-box h2 {
    font-size: 2.5rem;
  }
  
  .creators-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  
  .feature-image {
    height: 300px;
  }

  .footer-content {
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }

  .footer-left, .footer-right {
    align-items: center;
    text-align: center;
  }

  .footer-copyright {
    align-items: center;
  }
}

.section-title {
  padding: 0 1rem;
}

.logo-link {
  text-decoration: none;
  color: inherit;
}

.logo-link:hover {
  opacity: 0.9;
} 