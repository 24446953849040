.marquee-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 1rem 0;
  border-radius: 12px;
}

.marquee-inner {
  display: flex;
  gap: 1.5rem;
  padding: 0 1rem;
  will-change: transform;
  border-radius: 12px;
}

.marquee-item {
  flex: 0 0 180px;
  width: 180px;
  height: 230px;
  position: relative;
  cursor: pointer;
}

.creator-image {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;
  will-change: transform;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}

.creator-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: linear-gradient(to top, rgba(0,0,0,0.9) 0%, rgba(0,0,0,0) 100%);
  color: white;
  border-radius: 0 0 12px 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.creator-name {
  display: block;
  font-size: 1rem;
  font-weight: 800;
  margin-bottom: 0.25rem;
  letter-spacing: -0.08em;
  text-align: center;
}

.creator-stats {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}

.creator-instagram {
  width: 14px;
  height: 14px;
  opacity: 0.9;
  order: -1;
}

.creator-followers {
  font-size: 0.75rem;
  opacity: 0.8;
  text-align: center;
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .marquee-item {
    width: 160px;
    height: 204px;
  }
  
  .creator-info {
    padding: 0.75rem;
  }
  
  .creator-name {
    font-size: 0.875rem;
  }
  
  .creator-instagram {
    width: 12px;
    height: 12px;
  }
}

@media (max-width: 480px) {
  .marquee-item {
    width: 140px;
    height: 220px;
  }
  
  .creator-name {
    font-size: 1.1rem;
  }
  
  .creator-followers {
    font-size: 0.8rem;
  }
  
  .creator-instagram {
    width: 10px;
    height: 10px;
  }
  
  .creator-stats {
    gap: 0.2rem;
  }
} 